import React from 'react'

import Layout from '../components/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Bot = () => (
  <Layout>
    <div className="bg-black text-white">
      <div className="container mx-auto">
        <div className="px-4 py-10">
          <h1 className="font-light text-4xl">Support</h1>
        </div>
      </div>
    </div>

    <div className="container mx-auto pb-32">
      <div className="px-4 py-4 mb-8 leading-loose">
        <h2 className="pb-4 pt-8">Live Chat <FontAwesomeIcon className="text-pink ml-2" icon="comment" /> </h2>
        <p>
          Most of our customers love to contact us via live chat, which is the fastest
          and easiest way to get in touch. To chat now, just click the icon in the
          bottom-right of this page.
        </p>
        <h2 className="pb-4 pt-8">Email <FontAwesomeIcon className="text-pink ml-2" icon="envelope" /></h2>
        <p>
          You can get in touch with the team at any time by email,
          at <a href="mailto:hello@whoops.io" className="text-blue hover:text-blue-light">hello@whoops.io</a>
        </p>
        <h2 className="pb-4 pt-8">Security <FontAwesomeIcon className="text-pink ml-2" icon="shield-alt" /></h2>
        <p>
          If you want to talk to use about the security of our platform, please get in
          touch by email at <a href="mailto:security@whoops.io" className="text-blue hoer:text-blue-light">security@whoops.io</a>
        </p>
      </div>
    </div>
  </Layout>
)

export default Bot
